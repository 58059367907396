.link-card {
    background-color: white;
    font-family: 'Roboto', sans-serif;

    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.87);
    }

    a {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color:#2068C7;
    }

    small {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }

    &+.link-card {
        // margin-top: 1em;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
}