header {
    background-color: #FFFFFF;

    & > h1 {
        font-weight: 700;
        padding: 0px 20px;
    }

    .nav {
        border: 0px;
        flex-flow: row;
        text-align: center;
        align-items: flex-end;

        .nav-item {
            margin: 0px;

            .nav-link {
                border: 0px;

                border-bottom: solid transparent 6px;
                &.active {
                    border-bottom: solid $primary 6px;
                }
            }
        }
    }
}

.links-container {
    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 700px;
    }

    // @media (min-width: 992px) {
    //     max-width: 540px;
    // }

    // @media (min-width: 1200px) {
    //     max-width: 540px;
    // }
}