.search-box {

    width: 100%;
    margin: auto;

    // @media (min-width: 576px) {
    // }

    @media (min-width: 768px) {
        width: 75%;
    }

    @media (min-width: 992px) {
        width: 50%;
    }

    // @media (min-width: 1200px) {
    // }



    input[name="search-bar"] {
        padding: 13px 20px;
        background: #ffffff;
        border-radius: 5px;
        border: 0px;
        min-width: 0px;

        &::placeholder {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: 100%;
        }
    }

    button[name="search-button"] {
        width: 50px;
        margin: 0px;

        // .Streamline_Icon {
        //     svg {

        //     }
        // }
    }
}
