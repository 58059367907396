@import "process-card/process-card";

.processes-grid.grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 2rem;
    
    grid-template-rows: repeat(auto-fit, 390px);
    justify-content: space-between;

    
    @media (min-width: 576px) {
        --min-width: 368px;
    }

    @media (min-width: 768px) {
        --min-width: 334.583px;
    }

    @media (min-width: 992px) {
        --min-width: 309.417px;
    }

    @media (min-width: 1200px) {
        --min-width: 368px;
    }

    grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));

    .card-link {
        width: 100%;
        width: -webkit-fill-available;
    }
}

