$theme-colors: (
    "primary": #1EA59A,
    "secondary": #455869
    // "danger": teal
);

$primary: #1EA59A;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1200px
);

@import "~bootstrap/scss/bootstrap";

@import "components/components";
@import "pages/pages";

body {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    color: #34434e;

    background-color: #F1F3F8;
}