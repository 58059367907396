.process-card {
    display: grid;
    grid-template-rows: 65% 25% 10%;

    width: 100%;
    height: 390px;

    min-width: 290px;
    max-width: 370px;

    background-color: white;
    border: 1px solid #f1f3f4;
    box-shadow: none;
    border-radius: 5px;

    cursor: pointer;

    .card-body.card-footer-proc {
        padding: 0px;
        
        .proc-text {
            font-weight: 600;
            font-size: 1.1rem;
            color: #1e2022;
            margin-bottom: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .pac-text {
            font-weight: 500;
            color: #8997a2;
            margin-top: 0;
        }
    }

    .card-body.card-footer-container {
        border-block-start: 1px solid #f1f3f4;
        padding: 0px;

        .card-footer-proc-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            color: #8997a2;
            margin-left: 6.5px;
        }
    }    
    
    .card-img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        @media (min-width: 576px) {
            min-width: 368px;
        }

        @media (min-width: 768px) {
            min-width: 334.583px;
        }

        @media (min-width: 992px) {
            min-width: 309.417px;
        }

        @media (min-width: 1200px) {
            min-width: 368px;
        }
    }

    .stars {
        margin: 1.25px;
        width: 11.87px;
        height: 12.5px;
    }
}

.Streamline_Icon {
    &.middle {
        svg {
            vertical-align: middle;
        }
    }
}
